body {
  font-family: 'IBM Plex Sans', sans-serif;
}

.btn-lang {
    background-color: #171E3F;
    color: white !important;
    border-radius: 8px;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin-left: .75rem !important;
}

#language::after {
    margin-left: 10px;
}


.masthead {
  padding-top: calc(6rem + 74px);
}

.masthead .masthead-heading {
  font-size: 60px;
  line-height: 2.75rem;
  text-align: center;
}

.masthead .masthead-subheading {
  font-size: 1.25rem;
  margin-top: 30px;
  margin-bottom: 45px;
  text-align: center;
}

.masthead .masthead-avatar {
  width: 15rem;
}

@media (min-width: 992px) {
  .masthead {
    padding-top: calc(6rem + 104px);
  }

  .masthead .masthead-heading {
    font-size: 60px;
    line-height: 3.9rem;
  }

  .masthead .masthead-subheading {
    font-size: 1.3rem;
    margin-top: 30px;
  }

  #signup {
    font-size: 54px;
  }

  .coobs-video {
    height: 562px !important;
    width: 1000px !important;
  }

  .masthead-heading {
    margin-top: 30px;
  }
}

.masthead img {
    max-height: 350px;
}

.masthead h1 {
    font-weight:bold;
}

masthead-subheading {
  font-size: 1.3rem;
}

.custom-green {
  color: #00D334;
}

.bg-custom-green {
  background-color: #00D334;
}

.custom-blue {
  color: #00C3FF !important;
}

.btn-custom-blue {
  color: #FFFFFF !important;
  background-color: #04c5ee !important;
  font-weight: bold !important;
}

.custom-orange {
  color: #FF6A24;
}

.custom-violet {
  color: #171E3F;
}

.bg-grey {
  background-color: #F2F4F9;
}

.signup {
  border: solid 2px #171E3F;
  border-radius: 47px;
}

.signup input {
  border: solid 2px #171E3F;
  border-radius: 10px;
}

.footer {
  color: white;
  font-weight: 500;
}

.btn-register-dark, .btn-change-pass {
  background-color: #171E3F !important;
  color: white !important;
  border-radius: 10px !important;
}

#signup {
  font-size: 45px;
  font-weight: 900;
}

.section-title {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 48px;
}

.section-text  {
  font-size: 18px;
  color: #6c757d !important;
}

.sigin-text, .sigin-text:hover {
  text-decoration: none;
  color: #171E3F;
}

.sigin-text:hover {
  font-weight: bold;
}

.btn-register-dark:hover, .btn-change-pass:hover{
  background-color: #171E3F;
  color: white !important;
  font-weight: bold;
  border-radius: 10px !important;
}

.coobs-video {
  height: 315px;
  width: 560px;
}

.video-container {
  padding-top: 12px;
}

.masthead-heading {
  margin-top: 30px;
}

.public-actions-header-container {
  padding-top: 40px;
}

.hidden {
  display: none !important;
}

#public-actions-scroll-more {
  width: 100%;
  text-align: center;
  font-weight: normal;
  margin-top: 55px;
}

#public-actions-no-more {
  width: 100%;
  text-align: center;
  font-weight: normal;
  margin-top: 55px;
}

.card {
  width: 25rem !important;
  flex-direction: row !important;
  margin: 20px !important;
  min-height: 260px !important;
}

#all-cards {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.card-footer-custom {
  padding-top: .75rem;
}

.card-link {
  position: absolute;
  bottom: 20px;
}